<template lang="pug">
div(class="container")
  div(class="card")
    header(class="card-header")
      p(class="card-header-title") Merchants
    div(class="card-content")
      div(class="content")
        nx-table(
          urlAdd="merchant/add"
          urlEdit="merchant/edit"
          urlFetch="merchant/list"
          urlDelete="merchant/delete"
          ref="nxTable"
          :selectedColumns="[\
            'displayname',\
            'cosApi',\
            'xAuthAppId',\
            'cosUser',\
            'FIELD',\
            'debugMode',\
            'noWriteToCOS',\
          ]"
          :checkable="true"
          @fillEdit="fill($event)"
        )
          template(#form="props")
            div(class="modal-card" style="width: auto; min-width: 40vw;")
              header(class="modal-card-head")
                p(class="modal-card-title")  {{ props.modeAdd ? 'Add merchant' : 'Edit merchant' }}
              section(class="modal-card-body")
                b-field(label="displayname")
                  b-input(
                    type="string"
                    v-model="formData.displayname"
                    placeholder="displayname"
                    required
                  )
                b-field(label="cosApi")
                  b-input(
                    type="string"
                    v-model="formData.cosApi"
                    placeholder="cosApi"
                    required
                  )
                b-field(label="xAuthAppId")
                  b-input(
                    type="string"
                    v-model="formData.xAuthAppId"
                    placeholder="xAuthAppId"
                    required
                  )
                b-field(label="cosUser")
                  b-input(
                    type="string"
                    v-model="formData.cosUser"
                    placeholder="cosUser"
                    required
                  )
                b-field(label="cosPwd")
                  b-input(
                    type="string"
                    v-model="formData.cosPwd"
                    placeholder="cosPwd"
                    required
                  )
                b-field(label="nxToken")
                  b-input(
                    type="string"
                    v-model="formData.nxToken"
                    placeholder="nxToken"
                    required
                  )
                b-field(label="c1Token" v-if="!props.modeAdd")
                  b-input(
                    type="string"
                    v-model="formData.c1Token"
                    placeholder="c1Token"
                    required
                  )
                b-field(label="heartbeatSystem")
                  b-input(
                    type="string"
                    v-model="formData.heartbeatSystem"
                    placeholder="heartbeatSystem"
                    required
                  )
                b-field(label="heartbeatKey")
                  b-input(
                    type="string"
                    v-model="formData.heartbeatKey"
                    placeholder="heartbeatKey"
                    required
                  )
                b-field(label="FIELD")
                  b-input(
                    type="string"
                    v-model="formData.FIELD"
                    placeholder="FIELD"
                    required
                  )
                b-field(label="debugMode")
                  b-checkbox(
                    v-model="formData.debugMode"
                  )
                b-field(label="noWriteToCOS")
                  b-checkbox(
                    v-model="formData.noWriteToCOS"
                  )
                b-field(label="pauseTime")
                  b-input(
                    type="string"
                    v-model="formData.pauseTime"
                    placeholder="pauseTime"
                    required
                  )
                b-field
                  template(#label)
                    div.is-flex.is-flex-direction-row.is-align-content-center
                      span Financing
                      b-tooltip(position="is-right" multilined)
                        b-icon(icon="information-outline")
                        template(v-slot:content)
                          strong.has-text-white Example
                          pre(style="text-align: left") {{ JSON.stringify(financingExample, null, 2) }}
                  vue-json-editor(
                    v-model="formData.financingConfig"
                    :expanded-on-start="true"
                  )

              footer(class="modal-card-foot")
                b-button(
                  label="Close"
                  icon-left="close"
                  @click="$refs.nxTable.close()"
                )
                b-button(
                  label="Save"
                  icon-left="plus"
                  type="is-primary"
                  @click="$refs.nxTable.save(formData)"
                )
                b-message(v-if="props.error" title="error") {{ props.error }}

    footer(class="card-footer")
</template>

<script>
import vueJsonEditor from 'vue-json-editor';
import nxTable from '@nx-t/guzzi/vue/components/nxTable';

export default {
  components: {
    nxTable,
    vueJsonEditor,
  },
  data() {
    return {
      formData: {
        displayname: '',
        cosApi: '',
        xAuthAppId: 'NxTechnologies_bezahl.de',
        cosUser: '',
        cosPwd: '',
        nxToken: '',
        c1Token: '',
        heartbeatSystem: '',
        heartbeatKey: '',
        financingConfig: {},
        FIELD: 'bezahl.de',
        debugMode: false,
        noWriteToCOS: false,
        pauseTime: '02:00-04:00',
      },
      financingExample: {
        Finanzierung: {
          'received?': true,
        },
      },
    };
  },
  methods: {
    fill(editData) {
      this.formData = editData;
      if (!this.formData.financingConfig) {
        this.formData.financingConfig = {};
      }
    },
  },
};

</script>
